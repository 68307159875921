var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home overflow-hidden"},[_c('SmoothWrapper',{ref:"smoothWrapper",attrs:{"smooth-touch":""},on:{"scroll:ready":_vm.onScrollReady}},[_c('div',{ref:"track",staticClass:"track relative"},_vm._l((this.nbGrids),function(n){return _c('div',{key:n,ref:"grid",refInFor:true,staticClass:"slide container container--home auto-rows-max gap-gutter",class:{
          'absolute inset-x-0 lg:h-full': n > 1,
          'bottom-full': n === 2,
          'top-full': n === 3
        }},_vm._l((_vm.nbRows),function(row){return _c('div',{key:row,ref:"row",refInFor:true,staticClass:"slide-row col-span-full container container--home gap-gutter place-items-center"},_vm._l((_vm.artworks.slice((row - 1) * _vm.nbColumns, (row - 1) * _vm.nbColumns + _vm.nbColumns)),function(artwork,i){return _c('div',{key:artwork.id,ref:"item",refInFor:true,staticClass:"slide-item row-start-1 col-start-1 col-span-2 lg:col-start-1 lg:col-span-1 w-full"},[_c('div',{ref:"cardWiggle",refInFor:true,staticClass:"slide-card-wiggle"},[_c('div',{ref:"cardWrapper",refInFor:true,staticClass:"slide-card-wrapper"},[_c('CardArtwork',{ref:n === 1 && 'card',refInFor:true,staticClass:"slide-card",attrs:{"artwork":artwork,"tabindex":n > 1 && '-1',"data-index":i + (row - 1) * _vm.nbColumns},nativeOn:{"mouseenter":function($event){_vm.currentArtworkId = artwork.id},"mouseleave":function($event){_vm.currentArtworkId = ''}}})],1)])])}),0)}),0)}),0)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showTags),expression:"!showTags"}],staticClass:"fixed top-56 right-32 lg:!hidden"},[_c('UIToggle',{staticClass:"tags-toggle",attrs:{"toggle":_vm.showTags,"theme":"light","aria-controls":"HomeTags","aria-expanded":_vm.showTags.toString()},on:{"update:toggle":function($event){_vm.showTags=$event}}},[_vm._v("\n      "+_vm._s(_vm.$t('home.filters.open'))+"\n    ")])],1),_vm._v(" "),_c('div',{staticClass:"tags-container fixed inset-0 z-modal lg:z-auto lg:container container--home overflow-auto lg:overflow-visible lg:pointer-events-none before:fixed before:inset-0 before:bg-black/80 before:opacity-0 before:transition-opacity before:duration-1000 before:ease-in-out-cubic lg:before:hidden",class:{
      'pointer-events-none': _vm.isMobile && !_vm.showTags,
      'before:opacity-100': _vm.isMobile && _vm.showTags
    },attrs:{"id":"HomeTags","aria-hidden":(!_vm.showTags).toString()}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTags),expression:"showTags"}],staticClass:"lg:hidden fixed top-56 right-32"},[_c('UIToggle',{staticClass:"tags-toggle text-gray-light after:absolute after:inset-0 after:-m-32 pointer-events-auto",attrs:{"toggle":_vm.showTags,"theme":"light","aria-controls":"HomeTags","aria-expanded":_vm.showTags.toString()},on:{"update:toggle":function($event){_vm.showTags=$event}}},[_vm._v("\n        "+_vm._s(_vm.$t('home.filters.close'))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"lg:col-span-full flex justify-center items-center py-88 px-32 lg:p-0"},[_c('ul',{staticClass:"tags flex flex-col lg:flex-row lg:items-baseline gap-48 lg:gap-0 lg:justify-evenly w-full font-serif text-16 text-white"},_vm._l((_vm.tags),function(tag){return _c('li',{key:tag,staticClass:"tags-item relative flex justify-center before:content-[attr(data-tag)] before:block before:invisible",attrs:{"data-tag":tag}},[_c('button',{staticClass:"tag absolute lg:visible lg:opacity-100 whitespace-nowrap before:content-[attr(data-label)] before:absolute before:-z-1 before:opacity-0 pointer-events-auto",class:[
              _vm.showTags ? 'visible opacity-100' : 'invisible opacity-0',
              { 'active visible opacity-100': _vm.currentTag === tag }
            ],attrs:{"type":"button","data-label":tag},on:{"click":function($event){return _vm.selectTag(tag)}}},[_vm._v("\n            "+_vm._s(tag)+"\n          ")])])}),0)])]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTag),expression:"currentTag"}],staticClass:"arrow-container lg:hidden fixed inset-x-0 bottom-32 flex justify-center pointer-events-none"},[_c('UIBtn',{staticClass:"arrow",attrs:{"icon":"arrow"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }