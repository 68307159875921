import { render, staticRenderFns } from "./CardArtwork.vue?vue&type=template&id=65955b05&scoped=true&"
import script from "./CardArtwork.vue?vue&type=script&lang=ts&"
export * from "./CardArtwork.vue?vue&type=script&lang=ts&"
import style0 from "./CardArtwork.vue?vue&type=style&index=0&id=65955b05&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65955b05",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppImg: require('/github/workspace/components/AppImg.vue').default,UIBtn: require('/github/workspace/components/UIBtn.vue').default,AppLink: require('/github/workspace/components/AppLink.vue').default})
