import { render, staticRenderFns } from "./UIToggle.vue?vue&type=template&id=19c84436&"
import script from "./UIToggle.vue?vue&type=script&lang=ts&"
export * from "./UIToggle.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIBtn: require('/github/workspace/components/UIBtn.vue').default})
